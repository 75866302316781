import React from 'react';
import './HeroSection.css'; // Path to your CSS file
import backgroundImg from '../../images/Red Cad_MA site copy.jpg'; // Path to your background image
// import logo from '../../images/MartinResults-M_Logo_Rev.png'; // with import



const HeroSection = () => {
  return (
    <div className="HeroScreenfullScreen" style={{ backgroundImage: `url(${backgroundImg})` }}>
      <div className="heroBottomRow">
       
        <div className='heroLogoDiv'/>
        <div className='heroMessageDiv'>
          <h2 >Driven by results.</h2>
        </div>

      </div>
    </div>
  );
};

export default HeroSection;
