import React from 'react';
import './ServicesSection.css';

// Define your services as an array of objects if you need additional properties like URLs
const services = [
  { name: "Co-Op & Group", url: "/co-op-group" },
  { name: "Branding", url: "/branding" },
  { name: "Digital", url: "/digital" },
  { name: "Shopper & Retail", url: "/shopper-retail" },
  { name: "Media", url: "/media" },
  { name: "Production", url: "/production" },
  { name: "Research & Insights", url: "/research-insights" },
  { name: "Direct", url: "/direct" }
];

const ServicesList = () => {
  // Split the services into two columns
  const half = Math.ceil(services.length / 2);
  const firstHalfServices = services.slice(0, half);
  const secondHalfServices = services.slice(half);

  return (
    <div className="services-container">
      <div className="service-title">Services</div>
      <div className='services-section'>
        <div className="service-column">
          {firstHalfServices.map(service => (
            <div className='service-item'>{service.name}</div>
          ))}
        </div>
        <div className="service-column">
          {secondHalfServices.map(service => (
            <div className='service-item'>{service.name}</div>
          ))}
        </div>
      </div>

    </div>
  );
};

export default ServicesList;
