import React from 'react';
import './HistorySection.css';
import Logo from '../../images/MartinResults-M_Logo_Rev.png'

const HistorySection = () => {
    return (
        <div className="history-section">
            <div className="upper-section">
                A Unique Approach – Exponential Results
            </div>
            <div className="lower-section">
                <div className='lowerSectionLogo' />
                <div className='nameDiv'>Relationships lead to longevity, and for nearly 50 years, Martin has successfully delivered significant results for clients based on our commitment of treating people like family, and doing things the right way.  No matter what. </div>
                <div className='nameDiv'>This simple but unique approach provides our clients with a dedicated team of extremely talented individuals, obsessed with delivering results and building relationships.  We do not succeed unless our client succeeds.</div>
                <div className='nameDiv'>Let’s grow together.</div>
            </div>
        </div>
    );
};

export default HistorySection;
