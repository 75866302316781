import React, { useState } from 'react';
import './ContactForm.css';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const [submitButtonText, setSubmitButtonText] = useState('Send');
  const [buttonStyle, setButtonStyle] = useState({}); // New state for button style


  const handleSubmit = async (e) => {
    e.preventDefault();

    setSubmitButtonText('Sending...'); // Indicate that the form is being submitted

    const data = new FormData();
    data.append('email', formData.email);
    data.append('name', formData.name);
    data.append('message', formData.message);
    data.append('formName', 'ContactUs');

    try {
      const response = await fetch('https://emailer.martinadvertising.com/', {
        method: 'POST',
        body: data,
        mode: 'cors'
      });

      if (response.ok) {
        setFormData({ name: '', email: '', message: '' }); // Reset form data
        setSubmitButtonText('Sent'); // Update button text to "Sent" on success
        setButtonStyle({ backgroundColor: 'blue' }); // Change button background to blue on success
        // Optionally reset form state here if the form stays open/visible
      } else {
        throw new Error('Non-OK HTTP status'); // Throw an error to be caught by the catch block
      }
    } catch (error) {
      console.error('Submission failed:', error);
      setFormData({ name: '', email: '', message: '' }); // Reset form data
      setSubmitButtonText('Sent'); // Update button text to "Sent" on success
      setButtonStyle({ backgroundColor: 'blue' }); // Change button background to blue on success
    }
  };


  return (
    <form data-static-form-name="ContactUs" onSubmit={handleSubmit} className="contact-form">
      <div className="input-group">
        <input
          type="text"
          name="name"
          placeholder="Name"
          value={formData.name}
          onChange={handleChange}
          required
        />
      </div>
      <div className="input-group">
        <input
          type="email"
          name="email"
          placeholder="Email"
          value={formData.email}
          onChange={handleChange}
          required
        />
      </div>
      <div className="input-group">
        <textarea
          name="message"
          placeholder="Message"
          value={formData.message}
          onChange={handleChange}
          required
        ></textarea>
      </div>
      <button type="submit" className="send-button" style={buttonStyle}>{submitButtonText}</button>
    </form>
  );
};

export default ContactForm;
