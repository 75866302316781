import React from 'react';
import './CareerListing.css';
import { Link } from 'react-router-dom';

function CareerListing({ careers }) {
    return (
      <div className="career-listing">
        {careers.map(career => (
          <Link to={`/careers/${career.id}`} state={{ career: career }} key={career.id} className="career-item">
            <div>
              <div className="career-title">{career.title}</div>
              <div className="career-location">{career.location}</div>
            </div>
            <div className="career-arrow">{'>'}</div>
          </Link>
        ))}
      </div>
    );
  }

export default CareerListing;
