import logo from './logo.svg';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import HomePage from './pages/HomePage';
import CareerPage from './pages/CareerPage';
import CareerDetailsPage from './pages/CareerDetailsPage';
import { CareerProvider } from './CareerContext';
import Footer from './components/Footer';


function App() {

  return (
    <CareerProvider>
      <Router>
        <div>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/CareerPage" element={<CareerPage />} />
            <Route path="/careers/:careerId" element={<CareerDetailsPage/>} />
          </Routes>
          <Footer/>
        </div>
      </Router>
    </CareerProvider>

  );
}

export default App;
