import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import Header from '../../components/Header';
import React, { useState, useEffect } from 'react';
import ServicesSection from '../../components/ServicesSection'
import HeroSection from '../../components/HeroSection';
import MissionStatement from '../../components/MissionStatement';
import ClientSection from '../../components/ClientSection';
import HistorySection from '../../components/HistorySection';
import '../../App.css';


function HomePage() {
  return (
    <div className="App">
        <Header />
        <HeroSection />
        <MissionStatement />
        <ClientSection />
        <HistorySection />
        <ServicesSection />
    </div>
  );
}

export default HomePage;
