// CareerContext.js

import React, { createContext, useState, useEffect } from 'react';

// Create a context for careers
export const CareerContext = createContext();

const careerData = [
    // { id: 1, title: 'Assistant Account Executive', description: 'This is a full-time hybrid role for an Assistant Account Executive located in the Greater Birmingham, Alabama Area. The Assistant Account Executive will be assisting in managing client relationships, creating and managing project timelines, preparing proposals and presentations, conducting research and analysis, and collaborating with team members. Some remote work is acceptable.', qualifications: ['Excellent communication, organization, and time management skills','Ability to prioritize tasks and meet deadlines','Experience in client management and relationship building','Proficiency in Microsoft Office Suite and project management software','Knowledge of marketing and advertising principles','Bachelor\'s degree in Marketing, Communications, Business, or a related field','Experience in the automobile industry is a plus'],additionals: ['Knowledge of the automotive industry','Past work within Direct Marketing','Ability to work within and/or knowledge of AI tools'] },
 
]


export const CareerProvider = ({ children }) => {
  const [careers, setCareers] = useState([]);

  useEffect(() => {
    // Placeholder for where you would fetch the career data from an API
    const fetchCareers = async () => {
      setCareers(careerData);
    };

    fetchCareers();
  }, []);

  return (
    <CareerContext.Provider value={careers}>
      {children}
    </CareerContext.Provider>
  );
};
