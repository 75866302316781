import React, { useState } from 'react';
import Modal from 'react-modal';
import './ApplyModule.css';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '60%', // Adjust based on preference
        maxWidth: '600px', // Maximum width of the modal
        height: 'auto', // Adjust height automatically based on content
        maxHeight: '90vh', // Maximum height to ensure it doesn't overflow the viewport
        overflow: 'auto', // Enable scrolling for overflowing content
        display: 'flex', // Use flexbox for layout
        flexDirection: 'column', // Stack children vertically
        gap: '20px', // Space between children
        padding: '20px', // Inner padding of the modal
        borderRadius: '10px', // Rounded corners
        backgroundColor: '#fff', // Background color of the modal
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.75)', // Semi-transparent overlay
    },
};

Modal.setAppElement('#root');

const FileInput = ({ label, name, onFileSelect }) => {
    const [fileName, setFileName] = useState('');

   
    const handleFileChange = (e) => {
        if (e && e.target) {
            const file = e.target.files[0];
            if (file) {
                setFileName(file.name);
                // Construct an event-like object to match handleFileChange in ApplyModule
                onFileSelect({ target: { name: e.target.name, files: e.target.files } });
            } else {
                setFileName('');
            }
        }
    };

    const handleAttachmentClick = () => {
        document.getElementById(label).click();
    };

    return (
        <div style={{ margin: '10px 0' }}>
            <label>{label}</label>
            <a href="#" onClick={handleAttachmentClick} style={{ marginLeft: '10px' }}>
                Attach
            </a>
            <span style={{ marginLeft: '10px' }}>{fileName}</span>
            <input
                type="file"
                id={label}
                name={name}
                onChange={handleFileChange}
                style={{ display: 'none' }} // Hide the default file input
                accept=".pdf,.doc,.docx" // Specify accepted file types
            />
        </div>
    );
};

const ApplyModule = ({ career, isOpen, setIsOpen }) => {
    const [formData, setFormData] = useState({
        email: '',
        message: '',
        resume: null,
        coverLetter: null,
    });
    const [submitButtonText, setSubmitButtonText] = useState('Send');
    const [buttonStyle, setButtonStyle] = useState({}); // New state for button style

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = (e) => {

        const { name, files } = e.target || {};
        const file = files ? files[0] : null;

        if (name && file) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: file
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        setSubmitButtonText('Sending...'); // Indicate that the form is being submitted

        const data = new FormData();
        data.append('email', formData.email);
        data.append('message', formData.message);
        data.append('resume', formData.resume);
        data.append('coverLetter', formData.coverLetter);

        // Append career information as needed
        data.append('careerTitle', career.title);
        data.append('formName', 'JobApplication');

        try {
            const response = await fetch('https://emailer.martinadvertising.com/', {
                method: 'POST',
                body: data,
                mode: 'cors'
            });

            if (response.ok) {
                setFormData({ name: '', email: '', message: '' }); // Reset form data
                setSubmitButtonText('Sent'); // Update button text to "Sent" on success
                setButtonStyle({ backgroundColor: 'blue' }); // Change button background to blue on success
                setIsOpen(false); // Close the modal or form
                // Optionally reset form state here if the form stays open/visible
            } else {
                throw new Error('Non-OK HTTP status'); // Throw an error to be caught by the catch block
            }
        } catch (error) {
            console.error('Submission failed:', error);
            setFormData({ name: '', email: '', message: '' }); // Reset form data
            setSubmitButtonText('Sent'); // Update button text to "Sent" on success
            setButtonStyle({ backgroundColor: 'blue' }); // Change button background to blue on success
            setIsOpen(false); // Close the modal or form
        }
    };

    return (
        <Modal isOpen={isOpen} onRequestClose={() => setIsOpen(false)} style={customStyles}>
            <h2>Apply for {career.title}</h2>
            <form data-static-form-name="JobApplication" className="ApplyModal" enctype="multipart/form-data"  onSubmit={handleSubmit}>

                <input type="email" name="email" placeholder="Your Email" onChange={handleInputChange} required />

                <textarea name="message" placeholder="Brief Message" onChange={handleInputChange} required />

                <FileInput label="Resume/CV" name="resume" onFileSelect={(file) => handleFileChange(file, 'resume')} />

                <FileInput label="Cover Letter" name="coverLetter" onFileSelect={(file) => handleFileChange(file, 'coverLetter')} />

                <button type="submit" style={buttonStyle}>{submitButtonText} Application</button>
            </form>
        </Modal>
    );
};

export default ApplyModule;
