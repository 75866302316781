import { useState, useEffect } from 'react';

const useHeaderVisibility = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [isTop, setIsTop] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    setIsTop(currentScrollY < 50); // Check if near the top of the page

    // If scrolling up or at the top of the page, show the header
    if (currentScrollY < lastScrollY || currentScrollY < 50) {
      setIsVisible(true);
    } else if (currentScrollY > lastScrollY) {
      // If scrolling down, hide the header
      setIsVisible(false);
    }

    setLastScrollY(currentScrollY); // Update the last scroll position
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY]);

  return { isVisible, isTop };
};

export default useHeaderVisibility;
