import React, { useState } from 'react';
import './ClientSection.css';
import Allstate from '../../images/Allstate_Mayhem.jpg'
import Buick from '../../images/Buick.jpg'
import GMC from '../../images/GMC.jpg'
import Cadillac from '../../images/Cadillac.jpg'
import FifthThird from '../../images/fifth.jpg'
import United from '../../images/united.jpg'
import Walmart from '../../images/Walmart.jpg'

const clients = {
  'Cadillac': `url(${Cadillac})`,
  'Allstate': `url(${Allstate})` ,
  'Buick': `url(${Buick})`,
  'GMC': `url(${GMC})`,
  'Fifth Third Bank': `url(${FifthThird})`,
  'Walmart': `url(${Walmart})`,
  'United Way': `url(${United})`,
  // Add more clients and images as needed
};

const ClientSection = () => {
    // Set the initial background image to the first client's image
    const initialClient = Object.keys(clients)[0];
    const [backgroundImage, setBackgroundImage] = useState(clients[initialClient]);
  
    const handleMouseEnter = (client) => {
      setBackgroundImage(clients[client]);
  };

  return (
    <div className="client-section" style={{ backgroundImage }}>
      <h1>Clients</h1>
      <div className='clients'>
        {Object.keys(clients).map((client) => (
          <div
            key={client}
            className="client-name"
            onMouseEnter={() => handleMouseEnter(client)}
          >
            {client}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ClientSection;