import React from 'react';
import useHeaderVisibility from '../../helpers/scrollTracker';
import { Link } from 'react-router-dom';
import './Header.css'; // Import your CSS styles

const Header = () => {
  const { isVisible, isTop } = useHeaderVisibility();

  return (
    <header className={`header ${isVisible ? 'visible' : 'hidden'} ${isTop ? 'top' : ''}`}>
      <Link to="/"style={{ textDecoration: 'none', color: 'inherit' }}>Martin Advertising</Link>
    </header>
  );
};

export default Header;
