import React, { useContext, useEffect } from 'react';
import './CareerPage.css'; // Path to your CSS file
import backgroundImg from '../../images/Red Cad_MA site copy.jpg'; // Path to your background image
import Header from '../../components/Header';
import CareerListing from '../../components/CareerListing';
import { CareerContext } from '../../CareerContext';


//listings array. to loop over in and populate the CareerListing Component

const CareerPage = () => {
    const careers = useContext(CareerContext);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Header />
            <div className="CP-Background">

                <div className='CP-bannerImage' />

                <div className="CP-mainContent">
                    <div className='CP-MC-top'>
                        <div className='CP-OpenRoles'>
                            <p>Open Roles</p>
                        </div>
                        <div className='CP-Message'>
                            <p>We are looking for amazing people diverse in thought, perspective, and culture to join our team at Martin Advertising. We check our egos at the door, roll up our sleeves, work hard, move fast, and support each other. </p>
                            <p>May our bravest ideas be to listen, to self-educate, and to understand.
                            </p>
                            <p>
                                If this resonates with you, please get in touch.
                            </p>
                        </div>

                    </div>

                    <div className='CP-MC-bottom'>
                        <CareerListing careers={careers} />
                    </div>

                </div>

                {/* <div className="bottomRow">
                    <div className='logoCareerDiv' />
                    <div className='messageDiv'>
                        <h2 >Driven by results.</h2>
                    </div>
                </div> */}
            </div>
        </>
    );
};

export default CareerPage;
