import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../../components/Header';
import './CareerDetailsPage.css';
// import ApplyButton from '../../components/ApplyButton';
import ApplyModule from '../../components/ApplyModule';
function CareerDetailsPage() {
  const [career, setCareer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { careerId } = useParams();

  const [isApplyModalOpen, setIsApplyModalOpen] = useState(false);


  useEffect(() => {
    const fetchCareerDetails = async () => {
      try {
        setLoading(true);
        // Simulate an API call to fetch the career details based on careerId
        // This is where you would actually make a fetch request or similar
        const careerData = await getCareerDataById(careerId); // Implement this function
        setCareer(careerData);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCareerDetails();
  }, [careerId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!career) {
    return <div>Career not found</div>;
  }

  return (

    <>
      <Header />
      <div className='careerDetailsPage'>
        <h2>{career.title}</h2>
        <p className='careerDescription'>{career.description}</p>


        <div className='cpBottomHalf'>
          <div className='cpBottomDetails'>
            <h2>Qualifications:</h2>
            <ul>
              {career.qualifications.map((qualification, index) => (
                <li className='cpListItems' key={index}>{qualification}</li>
              ))}
            </ul>
            <h2>Additional capabilities</h2>
            <ul>
              {career.additionals.map((additional, index) => (
                <li className='cpListItems' key={index}>{additional}</li>
              ))}
            </ul>
          </div>


        </div>

        <div className="AP-button-style" onClick={() => setIsApplyModalOpen(true)}>Apply</div>

        <ApplyModule career={career} isOpen={isApplyModalOpen} setIsOpen={setIsApplyModalOpen} />

      </div>
    </>

  );
}

export default CareerDetailsPage;

// Helper function to simulate fetching data by ID
async function getCareerDataById(id) {
  // Replace with actual data fetching logic
  const careers = [
    // { id: 1, title: 'Assistant Account Executive', description: 'This is a full-time hybrid role for an Assistant Account Executive located in the Greater Birmingham, Alabama Area. The Assistant Account Executive will be assisting in managing client relationships, creating and managing project timelines, preparing proposals and presentations, conducting research and analysis, and collaborating with team members. Some remote work is acceptable.', qualifications: ['Excellent communication, organization, and time management skills', 'Ability to prioritize tasks and meet deadlines', 'Experience in client management and relationship building', 'Proficiency in Microsoft Office Suite and project management software', 'Knowledge of marketing and advertising principles', 'Bachelor\'s degree in Marketing, Communications, Business, or a related field', 'Experience in the automobile industry is a plus'], additionals: ['Knowledge of the automotive industry', 'Past work within Direct Marketing', 'Ability to work within and/or knowledge of AI tools'] },
  ]
  return careers.find(career => career.id.toString() === id);
}