import React from 'react';
import './MissionStatement.css'; // Make sure the path to your CSS file is correct

const MissionStatment = () => {
  return (
    <div className="component">
      <p>Since 1977, Martin Advertising has been delivering results based on a relational approach.  From our founding in Birmingham, AL, the desire to see our clients succeed was as present as our desire to see ourselves succeed.  Our clients success is  our success.  We grow as our clients grow.  And we invest fully in the process of accomplishing our clients goals.  Delivering Results together.  As one Team.</p>
      <p>As a full-service marketing agency complete with onsite studio and production facility, we run at retail speed to get  your message to market and customers to your door.  From creative development, media planning and buying, direct marketing, website development, and much more, Martin has everything you need to deliver RESULTS.</p>
    </div>
  );
};

export default MissionStatment;
